<template>
  <transition name="modal">
    <div class="modal-mask" style="overflow-y: hidden">
      <div style="position: absolute;top: 0; right: 14px;">
        <button type="button" title="Close (Esc)" class="vue-lightbox-close" @click="$emit('close')" style="z-index: 1000">
              <span>
                <svg fill="white" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 512 512"><path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4 L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1 c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1 c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"></path></svg>
              </span>
        </button>
      </div>
      <div class="modal-container" @click.stop style="width: 100%">
        <div class="vue-lightbox-figure">
          <swiper :options="swiperOptionTop" ref="swiperTop">
            <swiper-slide v-for="oe in objectiveEvidencesByCategory.objective_evidences">
            <div style="width: 100%; height: 100vh;">
              <div class="oe-container">
                <div class="oe-header-content">
                  <div class="oe-header oe-header-before">
                    <div class="w-100 text-white font-weight-bold fa-4x">
                      BEFORE
                    </div>
                  </div>
                  <div class="oe-header oe-header-after">
                    <div class="w-100 text-white font-weight-bold fa-4x">
                      AFTER
                    </div>
                  </div>
                </div>
                <div class="objective-evidences-content">
                  <div class="oe-content before-oe" style="position: relative">
                    <template v-if="oe.before.length > 1">
                      <template v-for="image in oe.before">
                        <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                          <div class="p-2">
                            <div class="oe-image-card p-4" id="ia-attachment-card">
                              <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else-if="oe.before.length === 1">
                      <div class="col-md-12 mb-1 justify-content-center align-content-center cursor-pointer p-0" style="float: left; width: 99%; height: 99%">
                        <div class="p-2">
                          <div class="oe-image-card-one p-4" id="ia-attachment-card">
                            <div :style="{ minWidth: '50%', backgroundImage: 'url(' + oe.before[0].image_url + ')', width: 'auto%', height: '100%' }" class="background-contain">
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div v-else style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white; font-size: 16px">
                      No Image Attached
                    </div>
                  </div>
                  <div class="oe-content after-oe" style="position: relative">
                    <template v-if="oe.after.length > 1">
                      <template v-for="image in oe.after">
                        <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                          <div class="p-2">
                            <div class="oe-image-card p-4" id="ia-attachment-card">
                              <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else-if="oe.after.length === 1">
                      <div class="col-md-12 mb-1 justify-content-center align-content-center cursor-pointer p-0" style="float: left; width: 99%; height: 99%">
                        <div class="p-2">
                          <div class="oe-image-card-one p-4" id="ia-attachment-card">
                            <div :style="{ minWidth: '50%', backgroundImage: 'url(' + oe.after[0].image_url + ')', width: 'auto%', height: '100%' }" class="background-contain">
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div v-else style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white; font-size: 16px">
                      No Image Attached
                    </div>
                  </div>
                </div>
                <div class="oe-description">
                  <div class="w-75" style="font-size: 13px; margin: auto; text-align: center">
                    <span class="text-white" id="oe-description" @click="visitObjectiveEvidence(oe)">
                      {{ objectiveEvidencesByCategory.objective_evidences[activeIndex].description | stripHtmlTags}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            </swiper-slide>
          </swiper>
        </div>

      </div>
      <button :disabled="activeIndex === 0" @click="activeIndex--" type="button" title="Previous" class="swiper-button-prev vue-lightbox-arrow vue-lightbox-left" >
          <span>
            <svg fill="white" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 512 512"><path d="M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9 c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8 L213.7,256z"></path></svg>
          </span>
      </button>
      <button :disabled="objectiveEvidencesByCategory.objective_evidences.length === activeIndex + 1" @click="activeIndex++" type="button" title="Next" class="swiper-button-next vue-lightbox-arrow vue-lightbox-right">
          <span>
            <svg fill="white" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 512 512" xml:space="preserve"><path d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7 c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8 L298.3,256z"></path></svg>
          </span>
      </button>
    </div>
  </transition>
</template>

<script>

import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name : 'ViewObjectiveEvidences',
  data() {
    return {
      activeIndex : 0,
      swiperOptionTop: {
        centeredSlides: true,
        slidesPerView: 1,
        navigation: {
          nextEl: '.vue-lightbox-right',
          prevEl: '.vue-lightbox-left'
        }
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        slidesPerView: 4,
        centeredSlides: true,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.vue-lightbox-thumbnail-right',
          prevEl: '.vue-lightbox-thumbnail-left'
        }
      },
      keyDownPressed : false,
      keyUpPressed : false,
    }
  },
  methods : {
    handleKeyDown(event) {
      if (this.keyDownPressed === false) {
        this.keyDownPressed = true;
        if (event.keyCode === 37) {
          $('button.swiper-button-prev').first().click();
        }
        if (event.keyCode === 39) {
          $('button.swiper-button-next').first().click();
        }
      }
    },
    handleKeyUp() {
      this.keyDownPressed = false;
    },
    visitObjectiveEvidence(objectiveEvidence) {
      const route= this.$router.resolve(
        {
          name: 'OEMainDetails',
          params: {
            vessel_id : objectiveEvidence.vessel_id,
            id: objectiveEvidence.audit_record_id,
            type : objectiveEvidence.type,
            ncr_id : objectiveEvidence.ncr_id,
            ncr_type : objectiveEvidence.ncr_type,
            oe_id : objectiveEvidence.oe_id,
          },
        },
      )
      window.open(route.href,'_blank');
    }
  },
  props : {
    objectiveEvidencesByCategory : {
      type : Object,
      default : {}
    },
  },
  components: {
    swiper,
    swiperSlide
  },
  filters : {
    stripHtmlTags(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    }
  },
  mounted () {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      swiperTop.on('slideChange', () => {
        this.activeIndex = swiperTop.activeIndex
      });
    })
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  },
}
</script>

<style lang="css" scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  color: #fff;
  background-color: rgba(0, 0, 0, .8);
  display: flex;
  position: fixed;
  padding: 10px;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-align: center;
  box-pack: center;
  transition: opacity .3s ease;
  box-sizing: border-box;
}
.modal-container {

}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}

/*
 * Lightbox
 */

* {
  box-sizing: border-box;
}
.vue-lightbox-content {
  margin-bottom: 60px;
  max-width: calc(100vw - 30px);
  position: relative;
}
.vue-lightbox-header {
  display: flex;
  height: 40px;
  justify-content: space-between;
}
.vue-lightbox-close {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
  top: 0;
  vertical-align: bottom;
  height: 40px;
  margin-right: -10px;
  padding: 10px;
  width: 40px;
}
.vue-lightbox-figure {
  margin: 0;
  display: block;
  position: relative;
}
.vue-lightbox-figure .swiper-slide {
  margin: auto;
}
img.vue-lightbox-modal-image {
  max-height: calc(100vh - 140px);
  cursor: pointer;
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  user-select: none;
}
.vue-lightbox-info {
  visibility: initial;
  position: absolute;
  bottom: 0;
  color: #fff;
  background-color: rgba(0,0,0,.7);
  height: 40px;
  width: 100%;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  text-align: center;
}
.vue-lightbox-info h4 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}
.vue-lightbox-footer {
  box-sizing: border-box;
  color: #fff;
  cursor: auto;
  display: flex;
  left: 0;
  line-height: 1.3;
  padding: 5px 0;
  justify-content: space-between;
}
.vue-lightbox-footer-info {
  display: block;
  flex: 1 1 0;
}
.vue-lightbox-footer-count {
  color: hsla(0,0%,100%,.75);
  font-size: .85em;
  padding-left: 1em;
}

.vue-lightbox-thumbnail-wrapper {
  bottom: 10px;
  height: 50px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: auto;
}
.vue-lightbox-thumbnail {
  bottom: 10px;
  height: 50px;
  padding: 0 50px;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  width: 300px;
}

.vue-lightbox-thumbnail .swiper-container {
  background: transparent;
}

.vue-lightbox-thumbnail-arrow {
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  padding: 10px;
  position: absolute;
  top: 50%;
  user-select: none;
  height: 50px;
  margin-top: -23px;
  width: 30px;
}
.vue-lightbox-thumbnail-left {
  left: 10px;
}
.vue-lightbox-thumbnail-right {
  right: 10px;
}
.vue-lightbox-modal-thumbnail, .vue-lightbox-modal-thumbnail-active {
  background-position: 50%;
  background-size: cover;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 50px;
  margin: 2px;
  overflow: hidden;
  width: 50px;
  float: left;
}
.vue-lightbox-modal-thumbnail {
  box-shadow: inset 0 0 0 1px hsla(0,0%,100%,.2);
}
.swiper-slide-active .vue-lightbox-modal-thumbnail {
  box-shadow: inset 0 0 0 2px #fff;
}


.vue-lightbox-arrow {
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  padding: 10px;
  position: absolute;
  top: 50%;
  height: 120px;
  margin-top: -60px;
  user-select: none;
  width: 40px;
}
.vue-lightbox-left {
  left: 10px;
}
.vue-lightbox-right {
  right: 10px;
}

@media (min-width: 1200px) {
  .vue-lightbox-content {
    max-width: 1024px;
  }
}
@media (min-width: 768px) {
  .vue-lightbox-arrow {
    width: 70px;
  }
}
@media (min-width: 500px) {
  .vue-lightbox-thumbnail-arrow {
    width: 40px;
  }
}


.oe-images-container {
  min-height: 100vh;
  max-height: 60vh;
  padding: 10px;
  padding-top: 0;
}

.before-image-container, .after-image-container {
  overflow-y: auto;
  border: 1px solid white;
  border-radius: 5px;
  height: 80vh;
}

.oe-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height of the viewport */
}

.objective-evidences-content, .oe-header-content {
  flex: 1;
  display: flex; /* Use flexbox to arrange children in a row */
  overflow: hidden; /* Prevent overflow from affecting layout */
}

.oe-header {
  flex: 1; /* Take up equal space */
  margin-bottom: 20px;
}

.oe-content {
  flex: 1; /* Take up equal space */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-bottom: 20px;
  border: 1px solid white;
  border-radius: 10px;
}

.oe-header-before {
  margin-right: 30px;
}

.oe-header-after {
  margin-left: 30px;
}
.before-oe {
  margin-left: 60px;
  margin-right: 30px;
}

.after-oe {
  margin-left: 30px;
  margin-right: 60px;
}

.oe-header-content {
  flex: 1; /* Take up equal space */
  color: white;
  max-height: 7%;
  margin-left: 60px;
  margin-right: 60px;
}

.oe-description {
  flex-shrink: 0;
  color: white;
  min-height: 5%;
  height: auto; /* Initial height of the footer */
  max-height: 30%; /* Maximum height of the footer */
  overflow-y: auto; /* Scroll if content exceeds max-height */
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 10px;
}

.oe-image-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid white;
  border-radius: .25rem;
}

.oe-image-card-one {
  border: 1px solid white;
  border-radius: .25rem;
  position: absolute;
  top: 8px;
  left: 8px;
  height: 99%;
  width: 99%;
}

#ia-attachment-card:hover {
  background-color: #dae8f4 !important;
  transition: 0.5s;
}

#oe-description:hover {
  color : yellow !important;
  cursor: pointer;
}
</style>
